@import url("https://fonts.googleapis.com/css2?family=Princess+Sofia&family=Righteous&display=swap");

@import "~bootstrap/scss/bootstrap";

html {
  --color-primary: #fae5d3;
  --color-secondary: #2c3e50;
  --color-tertiary: #f5f5f5;
  --color-quaternary: #ffb085;
  --color-quinary: #a8dadc;
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-primary);
  color: var(--color-secondary);
  font-family: "Righteous", sans-serif;
}

.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.layout-outlet {
  flex: 1;
}

.globe {
  min-height: 40vh;
}
.globe canvas {
  min-height: 40vh;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 2s forwards 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.home-headings {
  opacity: 0;
  animation: fadeIn 1s forwards 1s;
}

.card-div {
  background-color: var(--color-tertiary);
  border-radius: 8px;
  width: 85%;
  max-width: 400px;
  cursor: pointer;
}

.back-image {
  width: calc(100px + 1vw);
  height: calc(100px + 1vw);
  aspect-ratio: 1/1 !important;
  border-radius: 4px;
  position: absolute;
  background-color: var(--color-quinary);
  transform: rotate(170deg) translateX(7px) translateY(9px);
}
.back-image2 {
  width: calc(100px + 1vw);
  height: calc(100px + 1vw);
  aspect-ratio: 1/1 !important;
  border-radius: 4px;
  position: absolute;
  background-color: var(--color-quaternary);
  transform: rotate(175deg) translateX(4px) translateY(4px);
}

.card-div img {
  width: calc(100px + 1vw);
  height: calc(100px + 1vw);
  aspect-ratio: 1/1 !important;
  object-fit: cover;
  border-radius: 4px;
  z-index: 2;
}

.princess-sofia {
  font-family: "Princess Sofia", sans-serif;
}

.collapse-buttons {
  gap: 10px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

.collapse-buttons.expanded {
  max-height: 100px;
}

.adventure-image {
  width: 85%;
}
.adventure-image img {
  border-radius: 4px;
}

img {
  max-width: 500px;
  margin: 0 auto;
}

.preview-container {
  min-width: 85%;
  background-color: var(--color-primary);
  border-radius: 4px;
}

.scroll-arrow svg {
  color: var(--color-secondary) !important;
  font-size: 2em;
}

.sort-icon-container {
  margin-bottom: -10px;
  margin-top: -20px;
  width: 85%;
  max-width: 400px;
}

.sort-icon {
  font-size: 2em;
  cursor: pointer;
}

.love-letter-container {
  width: 85%;
  max-width: 400px;
}

footer {
  min-height: 100px;
}
